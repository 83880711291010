<template>
  <CreateUpdateTemplate
    :customClass="'contract-update'"
    v-if="getPermission('contract:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        Update a Contract For
        <p
          v-on:click="customerDialog = true"
          class="m-0 cursor-pointer form-title-create-link pl-2"
          :class="{
            'text-ellipsis width-350px': !lodash.isEmpty(customerObject),
          }"
          link
        >
          <template v-if="lodash.isEmpty(customerObject)">Client Name</template>
          <template v-else>{{ customerObject.display_name }}</template>
          <v-icon
            link
            large
            color="cyan"
            class="mx-2"
            v-if="lodash.isEmpty(customerObject)"
            >mdi-plus-circle-outline</v-icon
          >
        </p>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Contract
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="contractForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Contract Subject
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Subject"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="contractCreate.subject"
                      dense
                      filled
                      label="Subject"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          contractCreate.subject,
                          'Contract Subject'
                        ),
                        validateRules.minLength(
                          contractCreate.subject,
                          'Contract Subject',
                          1
                        ),
                        validateRules.maxLength(
                          contractCreate.subject,
                          'Contract Subject',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Ref Job No.</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Ref Job No."
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="contractCreate.soled_job_no"
                      label="Ref Job No."
                      :rules="[
                        validateRules.minLength(
                          contractCreate.soled_job_no,
                          'Ref Job No.',
                          1
                        ),
                        validateRules.maxLength(
                          contractCreate.soled_job_no,
                          'Ref Job No.',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td class="font-size-16 pb-2" width="200">Project</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Name"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="projectList"
                      label="Project"
                      solo
                      flat
                      :disabled="pageLoading || !!+$route.query.project"
                      :loading="pageLoading"
                      hide-details
                      item-text="name"
                      item-value="id"
                      v-model.trim="contractCreate.project"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }} -
                            {{ item.name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'Start Date'"
                      v-model="contractCreate.start_date"
                      :defaultDate.sync="contractCreate.start_date"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">End Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'End Date'"
                      v-model="contractCreate.end_date"
                      :defaultDate.sync="defaultEndDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Contract number</td>
                  <td
                    colspan="2"
                    class="font-weight-700 font-size-16 pb-4"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model.trim="contractCreate.barcode"
                        label="Contract number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="contractCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          contractCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          contractCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Contract Type</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Contract Type"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="contractTypeList"
                      label="Contract Type"
                      solo
                      flat
                      hide-details
                      item-text="text"
                      item-value="value"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageContractTypeDialog = true"
                      v-model.trim="contractCreate.contract_type"
                      :rules="[
                        validateRules.required(
                          contractCreate.contract_type,
                          'Contract Type'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Contract Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Contract Value</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Contract Value"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="contractCreate.contract_value"
                      dense
                      filled
                      label="Contract Value"
                      solo
                      flat
                      hide-details
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-usd"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="contractCreate.description" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                :attachments.sync="attachments"
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
      <ManageContractType
        :commonDialog.sync="manageContractTypeDialog"
        :contractType.sync="contractTypeList"
        v-on:close-dialog="manageContractTypeDialog = false"
        v-on:get-contract-type="getOptions(['contract_type'])"
      ></ManageContractType>
      <template v-if="customerDialog">
        <CustomerDialog
          :customerDialog.sync="customerDialog"
          v-on:closeDialog="customerDialog = false"
          v-on:resetAll="customerDialog = false"
          v-on:selectCustomer="selectCustomer"
        ></CustomerDialog>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  PUT,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import ManageContractType from "@/view/pages/partials/Manage-Contract-Type.vue";
import moment from "moment-timezone";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "contract-update",
  title: "Update Contract",
  data() {
    return {
      customerDialog: false,
      pageLoading: false,
      barcodeDialog: false,
      manageContractTypeDialog: false,
      barcodeSetting: new Object(),
      customerObject: new Object(),
      contractTypeList: new Array(),
      projectList: new Array(),
      attachments: new Array(),
      contract: null,
      contractCreate: new Object({
        reference: null,
        soled_job_no: null,
        customer: null,
        project: null,
        subject: null,
        contract_value: null,
        contract_type: null,
        description: null,
        start_date: null,
        end_date: null,
        status: null,
        attachments: [],
      }),
    };
  },
  components: {
    DatePicker,
    FileTemplate,
    CustomerDialog,
    ManageContractType,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    selectCustomer(customer) {
      if (customer > 0) {
        this.$router
          .replace({
            name: this.$route.name,
            params: this.$route.params,
            query: { customer },
          })
          .then(() => {
            this.contractCreate.customer = customer;
            this.$nextTick(() => {
              this.getOptions(["customer", "contract_type", "project_list"]);
            });
          })
          .finally(() => {
            this.customerDialog = false;
          });
      }
    },
    updateAttachment(param) {
      this.contractCreate.attachments = param;
    },
    getOptions(attributes) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "contracts/options",
          data: {
            attributes,
            customer: _this.lodash.toSafeInteger(_this.contractCreate.customer),
          },
        })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data.customer) === false) {
            _this.customerObject = data.customer;
          }
          if (_this.lodash.isEmpty(data.contract_type) === false) {
            _this.contractTypeList = data.contract_type;
          }
          if (_this.lodash.isEmpty(data.project_list) === false) {
            _this.projectList = data.project_list;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.manageContractTypeDialog = false;
        });
    },
    updateOrCreate() {
      const _this = this;
      if (!_this.$refs.contractForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        soled_job_no: _this.contractCreate.soled_job_no || null,
        reference: _this.contractCreate.reference || null,
        customer: _this.contractCreate.customer || null,
        project: _this.contractCreate.project || null,
        subject: _this.contractCreate.subject || null,
        contract_value: _this.contractCreate.contract_value || null,
        contract_type: _this.contractCreate.contract_type || null,
        description: _this.contractCreate.description || null,
        start_date: _this.contractCreate.start_date || null,
        end_date: _this.contractCreate.end_date || null,
        status: _this.contractCreate.status || null,
        attachments: _this.contractCreate.attachments || [],
      });

      _this.$store
        .dispatch(PUT, {
          url: "contracts/" + _this.contract,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("contract.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getContract() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "contracts/" + _this.contract,
        })
        .then(({ data }) => {
          console.log({ data });
          _this.contractCreate = new Object({
            barcode: data.barcode,
            reference: data.reference,
            soled_job_no: data.soled_job_no,
            customer: data.customer ? data.customer.id : null,
            project: data.project ? data.project.id : null,
            subject: data.subject,
            contract_value: data.contract_value,
            contract_type: data.contract_type,
            description: data.description,
            start_date: data.start_date,
            end_date: data.end_date || "",
            attachments: new Array(),
          });
          _this.attachments = data.attachments;

          if (_this.lodash.toSafeInteger(data.line_item)) {
            _this.goBack();
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.getOptions(["customer", "contract_type", "project_list"]);
        });
    },
  },
  created() {
    const _this = this;
    _this.contractCreate.customer = _this.lodash.toSafeInteger(
      _this.$route.query.customer
    );
    if (_this.contractCreate.customer <= 0) {
      _this.goBack();
    }
    _this.contract = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (_this.contract <= 0) {
      _this.goBack();
    }

    _this.getContract();
  },
  mounted() {
    const _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Contract", route: "contract" },
      { title: "Update" },
    ]);
  },
  computed: {
    defaultEndDate() {
      if (this.contractCreate.end_date) {
        return this.contractCreate.end_date;
      }
      return moment().add(30, "days").format("YYYY-MM-DD");
    },
  },
};
</script>
